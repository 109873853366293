import AXIOS from 'axios';
import parameters from '../parameters';
import { API_CONFIG } from './config';
import { notification } from 'antd';

type Server = "distribution" | "common" | "lender";

function getPublicInstance(server: Server = "distribution") {
    const user = localStorage.getItem('user');

    const headers: any = {};
    let apiUrl = parameters.ApiUrlDis;
    if (server == "common") {
        apiUrl = parameters.ApiUrlCommon;
    } else if (server == "lender") {
        apiUrl = parameters.ApiUrlLender;
    }

    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "6743e0681860ce20c8d98b7fcd76bd4b";
        // headers['api_id'] = "16e40d1ae463ca1fb138da75c41f3856";
    }
    return AXIOS.create({
        //@ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl + '/',
        headers: {
            'Accept': 'application/json',
            "Content-Type": 'application/json',
            ...headers,
        }
    })
}
function getProtectedInstanceForDocument(server: Server = "distribution") {
    const user = localStorage.getItem('user');

    const headers: any = {};
    let apiUrl = parameters.ApiUrlDis;
    if (server == "common") {
        apiUrl = parameters.ApiUrlCommon;
    } else if (server == "lender") {
        apiUrl = parameters.ApiUrlLender;
    }

    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "6743e0681860ce20c8d98b7fcd76bd4b";
        // headers['api_id'] = "16e40d1ae463ca1fb138da75c41f3856";
    }
    return AXIOS.create({
        // @ts-ignore
        // 'method': 'GET',
        'accept': 'application/octet-stream',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/octet-stream',
            'Content-Type': 'application/octet-stream',
            ...headers,
        },
    });
}

function getProtectedInstanceForUploadDocument(server: Server = "distribution") {
    const user = localStorage.getItem('user');

    const headers: any = {};
    let apiUrl = parameters.ApiUrlDis;
    if (server == "common") {
        apiUrl = parameters.ApiUrlCommon;
    } else if (server == "lender") {
        apiUrl = parameters.ApiUrlLender;
    }

    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "6743e0681860ce20c8d98b7fcd76bd4b";
        // headers['api_id'] = "16e40d1ae463ca1fb138da75c41f3856";
    }
    return AXIOS.create({
        // @ts-ignore
        // 'method': 'GET',
        'accept': '*/*',
        'baseURL': apiUrl,
        'headers': {
            Accept: '*/*',
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryqcan9VibjFMWTPnv',
            ...headers,
        },
    });
}

function getProtectedInstanceForBlob(server: Server = "distribution") {
    const user = localStorage.getItem('user');
    const headers: any = {};
    let apiUrl = parameters.ApiUrlDis;
    if (server == "common") {
        apiUrl = parameters.ApiUrlCommon;
    } else if (server == "lender") {
        apiUrl = parameters.ApiUrlLender;
    }

    if (user) {
        headers['api_key'] = JSON.parse(user as string).apikey;
        headers['api_id'] = JSON.parse(user as string).apiId;
        // headers['api_key'] = "9857b26f64df7c67e7cb75d6cc857a78";
        // headers['api_id'] = "2feccf832a8bf2f6a23520230b5c2aba";
    }
    return AXIOS.create({
        // @ts-ignore
        'accept': 'application/json',
        'baseURL': apiUrl,
        'headers': {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...headers,
        },
        responseType: 'blob'
    });
}


function handleErrors(error: any, isReturn: boolean = false) {
    console.log('error', error);
    let message = 'Something went wrong!!';
    if (error && error.response && error?.response?.error) {
        let parsedError: any = '';
        message = error.response.data.error;
        if (typeof error.response.data.error === 'string') {
            try {
                parsedError = JSON.parse(error.response.data.error);
                if (parsedError['errorMessage']) {
                    message = parsedError['errorMessage'];
                } else {
                    const allErrors = Array();
                    for (let i in parsedError) {
                        allErrors.push(parsedError[i]);
                    }
                    message = allErrors.join(', ');
                }
            } catch (e) {
            }
        }

    }
    else if (error?.response?.data?.payload?.errorMessage) {
        message = error.response.data.payload.errorMessage;
        try {
        let arrMessage = message.split(" {")
        if ((arrMessage?.length ?? 0) <= 1) {
            arrMessage = message.split("400{");
        }
        if ((arrMessage?.length ?? 0) <= 1) {
            arrMessage = message.split("403{");
        }
        if ((arrMessage?.length ?? 0) > 1) {
            const objMessage = JSON.parse("{" + arrMessage[1])
            if (objMessage?.errors) {
                if ((objMessage?.errors?.length ?? 0) > 0) {
                    let msg = '';
                    objMessage?.errors?.filter((item: any) => {
                        msg = item.errorCodeDescription + ',' + msg;
                    })
                    message = msg ?? msg
                }
            }
            message = objMessage?.message ?? message
        }
    }catch {
            
    }
    }
    else if (error && error.response && error.response.data && error.response?.data?.message) {
        return message = error.response.data.message;
    }
    else if (error && error.response && error.response.data && error?.response?.data?.errors) {
        let e = Array();
        for (let i in error.response?.data?.errors) {
            e.push(`${i}: ${error.response.data.errors[i]}`);
        }
        message = e.join(',');
    } else if (typeof error === 'string') {
        message = error;
    }
    notification.error({ message: message });
    if(isReturn) {
        return message;
    }
}


async function login(payload: object) {
    const instance = getPublicInstance("common");
    return await instance.post('free/login/email/validate', payload);
}

async function getOtp(email: any) {
    const instance = getPublicInstance("common");
    return await instance.get(API_CONFIG.getOtp + email);
}

async function verifyOtp(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post(API_CONFIG.verifyOtp, payload);
}

async function setUpdatePassword(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post(API_CONFIG.setUpdatePassword, payload);
}

async function getTenantList(params: any) {
    const instance = getPublicInstance();
    return await instance.get('list/connectors/no-tenant', { params });
}

async function assignDataList(connectorId: any, dsaBusinessId: any) {
    const instance = getPublicInstance();
    return await instance.put('connector/update/dsa-business?connectorId=' + connectorId + '&dsaBusinessId=' + dsaBusinessId);
}

async function getAppContent(type: String, params?: any) {
    const instance = getPublicInstance("common");
    return await instance.get(
        API_CONFIG.listAppContent + 'BU6098926543/' + type,
        { params },
    );
}

async function getAppContentFAQ(type: String, params: any) {
    const instance = getPublicInstance("common");
    return await instance.get(
        API_CONFIG.listAppContentFAQ + 'BU6098926543/' + "FAQ?pageNo=1&pageSize=100",
        { params },
    );
}

async function addAppContent(params: any) {
    const instance = getPublicInstance("common");
    return await instance.post(
        API_CONFIG.addAppContent + 'BU6098926543',
        params,
    );
}

async function updateAppContent(params: any, id: any) {
    const instance = getPublicInstance("common");
    return await instance.post(
        API_CONFIG.updateAppContent + '/' + 'BU6098926543' + '/' + id,
        params,
    );
}

async function deleteAppContent(id: any) {
    const instance = getPublicInstance("common");
    return await instance.post(
        API_CONFIG.deleteAppContent + '/' + 'BU6098926543' + '/' + id,
    );
}

async function assignToSearch(params?: any) {
    const instance = getPublicInstance("common");
    return await instance.get('business/list/tenants/filter', { params });
}

async function getListTenants(params?: any) {
    const instance = getPublicInstance("common");
    return await instance.get('business/list/tenants', { params });
}

async function updateTenant(tenantId: string, params?: any) {
    const instance = getPublicInstance("common");
    return await instance.post(`business/update/tenant/${tenantId}`, params);
}

async function getLoanProduct(tanentId: any) {
    const instance = getPublicInstance("distribution");
    return await instance.get('/list/' + tanentId + '/loan_product');
}

async function getAllLoanProduct() {
    const instance = getPublicInstance("common");
    return await instance.get('/list/loan_product');
}

async function getPropertyList(params?: any) {
    const instance = getPublicInstance("common");
    return await instance.get('master/property/v1/list', { params });
}

async function getSubPropertyList(id?: any) {
    const instance = getPublicInstance("common");
    return await instance.get('master/property/v1/get/' + id);
}

async function editPropertyData(id: any) {
    const instance = getPublicInstance("common");
    return await instance.get('master/property/v1/get/' + id);
}

async function addProperty(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post('master/property/v1/create', payload);
}

async function deleteProperty(id: any) {
    const instance = getPublicInstance("common");
    return await instance.delete(`master/property/v1/delete/${id}`)
}

async function updateProperty(id: any, payload: any) {
    const instance = getPublicInstance("common");
    return await instance.put(`master/property/v1/update/${id}`, payload)
}

async function fileUpload(params: any) {
    const instance = getProtectedInstanceForDocument("common");
    return await instance.post('free/fileupload', params);
}

async function addDocumentTenant(businessId: any, params: any) {
    const instance = getPublicInstance("common");
    return await instance.put('document-upload/tenant/' + businessId + '/logo/documentId', params, {});
}
async function editDocumentList(id: any) {
    const instance = getPublicInstance("common");
    return await instance.get('document-upload/list/' + id);
}

async function deleteDocument(businessId: any, documentId: any) {
    const instance = getPublicInstance("common");
    return await instance.post(`document-upload/delete/${businessId}/${documentId}`);
}

async function getCollectionList(params: any) {
    const instance = getPublicInstance("common");
    return await instance.get('master/property-collection/v1/list', { params });
}

async function getObjectList(params: any) {
    const instance = getPublicInstance("common");
    return await instance.get('object-template/v1/list', { params });
}

async function createObject(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post('object-template/v1/create', payload)
}

async function updateObject(id: any, payload: any) {
    const instance = getPublicInstance("common");
    return await instance.put(`object-template/v1/${id}/update/`, payload)
}

async function editObjectData(id: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`object-template/v1/${id}/get/`);
}

async function getObjectProperty(id:any) {
    const instance = getPublicInstance('common');
    return await instance.get(`object-template/v1/${id}/get`)
}

async function deleteObject(id: any) {
    const instance = getPublicInstance("common");
    return await instance.delete(`object-template/v1/${id}/delete/`);
}

async function createCollection(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post('master/property-collection/v1/create', payload)
}

async function editCollectionData(id: any) {
    const instance = getPublicInstance("common");
    return await instance.get('master/property-collection/v1/get/' + id);
}


async function updateCollection(id: any, payload: any) {
    const instance = getPublicInstance("common");
    return await instance.put(`master/property-collection/v1/update/${id}`, payload)
}

async function deleteCollection(id: any) {
    const instance = getPublicInstance("common");
    return await instance.delete(`master/property-collection/v1/delete/${id}`);
}

async function getUploadDocument(id: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`document-upload/get/${id}`);
}

async function getProductList(params: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`product/v1/list`, { params });
}

async function getEditProductData(id: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`product/v1/get/${id}`);
}

async function createProduct(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post('product/v1/create', payload)
}

async function updateProduct(id: any, payload: any) {
    const instance = getPublicInstance("common");
    return await instance.put(`product/v1/update/${id}`, payload)
}

async function productFormTemplate(params: any) {
    const instance = getPublicInstance("common");
    return await instance.post(API_CONFIG.productFormTemplate, params);
}

async function startProductWorkflow(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post(API_CONFIG.startProductWorkflow, payload);
}

async function submitProductWorkflow(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post(API_CONFIG.submitProductWorkflow, payload);
}

async function getNextTaskProductWorkflow(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post(API_CONFIG.getNextTaskProductWorkflow, payload);
}

async function getCategoryList() {
    const instance = getPublicInstance("common");
    return await instance.get(`v1/product-type/all`);
}

async function getTypes() {
    const instance = getPublicInstance("common");
    return await instance.get("enum-values?loggedInFrom=web");
}

async function generateReportByReportType(startDate: string, endDate: string, reportType?: any) {
    const instance = getProtectedInstanceForBlob();
    return await instance.get(`admin/report?startDate=${startDate}&endDate=${endDate}&reportType=${reportType}`)
}

async function cleanLeads(caseIds: any) {
    const instance = getPublicInstance();
    return await instance.get(`helper/clean/leads?caseIds=${caseIds}`)
}

async function getMasterProductList(params?:any) {
    const instance = getPublicInstance('common');
    return await instance.get('product/v1/list', {params})
}

async function productClone(params?:any) {
    const instance = getPublicInstance('common');
    return await instance.post('product/v1/clone', params)
}

async function mapMasterLoanProduct(params?:any) {
    const instance = getPublicInstance('common');
    return await instance.post('product/v1/map/master-product/loan-product', params)
}

async function mapJourneyConnector(businessId: string, loanProductId:string, params?:any) {
    const instance = getPublicInstance('common');
    return await instance.put(`product/v1/map-upsert/journey/connector/${businessId}/${loanProductId}`, params)
}

async function addProductJourney(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('v1/product-workflow/basic-details', payload)
}

async function getProductWorkflowById(id:string) {
    const instance = getPublicInstance('common');
    return await instance.get(`v1/product-workflow/${id}`)
}

async function formTemplateList() {
    const instance = getPublicInstance('common');
    return await instance.get('v1/form-template/list')
}

async function updateProductJourney(id: string, payload:any) {
    const instance = getPublicInstance('common');
    return await instance.put(`v1/product-workflow/${id}/stages`, payload)
}

async function getDecisioningList(params: any) {
    const instance = getPublicInstance('common');
    return await instance.get(`decision/list`, {params})
}

async function addDecisioning(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('decision', payload)
}

async function editDecisioningData(id: string) {
    const instance = getPublicInstance('common');
    return await instance.get(`decision/${id}`)
}

async function updateDecisioning(id: string, payload: any) {
    const instance = getPublicInstance('common');
    return await instance.put(`decision/${id}`, payload)
}

async function updateDecisioningProfile(id: string, payload: any) {
    const instance = getPublicInstance('common');
    return await instance.put(`decision/profile/${id}`, payload)
}

async function duplicateDecisioning(id:any, payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post(`decision/duplicate/${id}`, payload)
}

async function getActionList(decisioningType: string) {
    const instance = getPublicInstance('common');
    return await instance.get(`decision/actions/list?decisionType=${decisioningType}`)
}

async function listAllTheFinanciers(params?:any) {
    const instance = getPublicInstance('common');
    return await instance.get('business/list/financier', {params})
}

async function getFiList(params?: any) {
    const instance = getPublicInstance("lender");
    return await instance.get("dsa/lender/list/fi", { params });
}

async function getPropertiesList(id: string, params?: any) {
    const instance = getPublicInstance('common');
    return await instance.get(`product/v1/get/${id}/properties?includeProductTypeProperties=true`, {params})
}

async function getScorecardPublisList(id: any) {
    const instance = getPublicInstance('common');
    return await instance.get(`decision/published/${id}`)
}

async function getDecisioningPublishList(params: any) {
    const instance = getPublicInstance('common');
    return await instance.get(`decision/published/list`, {params})
}

async function generateDecisioning(id: any, payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post(`decision/publish/${id}`, payload)
}

async function generateFormTemplate(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('product/v1/form-template', payload)
}

async function decisionVaildate(id:any) {
    const instance = getPublicInstance('common');
    return await instance.get(`decision/validate/metadata/${id}`)
}

async function testDecisionMaster(params: any) {
    const instance = getPublicInstance('common');
    return await instance.post(`decision/execute`, params)
}

async function validateConditionExpression(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('decision/validate/expression', payload);
}

async function updateStatusOfDocumentCategory(categoryId:any,status:any) {
    const instance = getPublicInstance('common');
    return await instance.patch(`document-config/category/${categoryId}/status/${status}`);
}

async function createDocumentCategory(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('document-config/category/create', payload);
}

async function updateDocumentCategorys(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.put('document-config/category/update', payload);
}

async function getDocumentCategorys(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('document-config/category/list', payload);
}

async function updateStatusOfDocumentType(docTypeId:any,status:any) {
    const instance = getPublicInstance('common');
    return await instance.patch(`document-config/type/${docTypeId}/status/${status}`);
}

async function createDocumentType(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('document-config/type/create', payload);
}

async function updateDocumentType(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.put('document-config/type/update', payload);
}

async function getDocumentTypes(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('document-config/type/list', payload);
}

async function createDocumentProduct(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('document-config/product-mapping/create', payload);
}

async function updateDocumentProduct(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.put('document-config/product-mapping/update', payload);
}

async function getDocumentProducts(productId:string, payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('document-config/product-mapping/list/' + productId, payload);
}
async function addProduct(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('product/v1/create', payload)
}

async function addDocument(businessId:string, payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post(`document-upload/add/${businessId}`, payload)
}

async function invoiceAddDocument(id:string, payload:any) {
    const instance = getPublicInstance("common");
    return await instance.post(`document-upload/free/add/documentset/${id}`, payload)
}

async function invoiceAddSignedDoc(payload: any) {
    const instance = getPublicInstance("distribution");
    return await instance.post('invoice/add-signed-documents', payload);
}

async function getProductTypes() {
    const instance = getPublicInstance("common");
    return await instance.get(`/v1/product-type/all`);
}

async function getProductActionList(id:any){
    const instance = getPublicInstance("common");
    return await instance.get(`product/v1/${id}/action/list`)
}

async function updateActionStatus(id:any,payload: any) {
    const instance = getPublicInstance('common');
    return await instance.put(`product/v1/${id}/update/actions`, payload)
}

async function productPropertiesList(params:any) {
    const instance = getPublicInstance('common');
    return await instance.get(`product/v1/product-property/list`, {params})
}

async function addProductProperty(id:string ,payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post(`product/v1/${id}/product-property`, payload)
}

async function addOfferCalculation(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post(`offer-calculation/create`, payload)
}

async function getOfferCalculationList(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post(`offer-calculation/list`, payload)
}
async function publishProductWorkflow(id:string, payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post(`v1/product-workflow/publish/${id}`, payload)
}

async function listProductWorkflowPublish(params:any) {
    const instance = getPublicInstance('common');
    return await instance.get('v1/product-workflow/publish/list', {params})
}

async function uploadOfferCalculation(id:any, params:any){
    const instance = getProtectedInstanceForUploadDocument("common");
    return await instance.post(`offer-calculation/upload-excel/${id}`, params)
}

async function updateOfferCalculation(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.put('offer-calculation/update', payload)
}

async function offerCalculationCopyJson(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('offer-calculation/copy', payload)
}

async function offerCalculationPasteJson(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('offer-calculation/paste', payload)
}

async function getOfferCalculationById(id:string){
    const instance = getPublicInstance('common');
    return await instance.get(`offer-calculation/${id}`);
}

async function offerCalculationPublishDetails(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.post('offer-calculation-publish/list', payload)
}

async function testOfferCalculationMaster(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('offer-calculation/execute', payload);
}

async function publishOfferCalculation(payload: any) {
    const instance = getPublicInstance('common');
    return await instance.post('offer-calculation/publish', payload)
}

async function publishOfferCalculationById(id:string) {
    const instance = getPublicInstance('common');
    return await instance.get(`offer-calculation-publish/${id}`)
}

async function getDefaultMasterWorkflow() {
    const instance = getPublicInstance('common');
    return await instance.get('workflow/v1/get-default');
}

async function getDispositionList(params:any) {
    const instance = getPublicInstance('common');
    return await instance.get('v1/dispositions', {params});
}

async function updateDisposition(payload:any) {
    const instance = getPublicInstance('common');
    return await instance.put('v1/disposition', payload);
}

async function getDecisioningAuditList(params: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`decision/audit`, { params });
}
async function getDecentroFinbox(params: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`decision/audit/vas`, { params });
}
async function getPublishDetails(params: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`decision/published/${params.decisioningPublishedId}`);
}

async function getProductProperty(id:string) {
    const instance = getPublicInstance('common');
    return await instance.get(`product/v1/get/${id}/properties`)
}

async function fetchMasterProductList(id:any, params:any) {
    const instance = getPublicInstance("common");
    return await instance.get(`product/v1/${id}/workflows`, {params});
}

async function addWorkFlowProduct(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post('v1/product-workflow/add', payload);
}

async function getWorkflowLitelist() {
    const instance = getPublicInstance("common");
    return await instance.get(`workflow/v1/lite-list`);
}

async function getStartFormConfiguration(id: string) {
    const instance = getPublicInstance("common");
    return await instance.get(`v1/product-workflow/${id}/start-form-configuration`);
}

async function updateStartFormConfiguration(id:any, payload:any) {
 const instance = getPublicInstance("common");
 return await instance.put(`v1/product-workflow/${id}/start-form-configuration`, payload);  
}

async function getAssistedJourneyDdlData(masterProductId: any) {
    const instance = getPublicInstance("common");
    return await instance.get(`v1/product-workflow/master-product/${masterProductId}`);
}

 async function checkCapchaRequired(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post(API_CONFIG.checkCaptchaRequired, payload);
}

async function fetchProductPlanList(params:any) {
    const instance = getPublicInstance("common");
    return await instance.get(API_CONFIG.productPlanList,{params});
}

async function addProductPlan(payload: any) {
    const instance = getPublicInstance("common");
    return await instance.post(API_CONFIG.productPlanList, payload);
}

async function deleteProductPlanById(productPlanId: any) {
    const instance = getPublicInstance("common");
    return await instance.delete(API_CONFIG.productPlanList + '/' + productPlanId)
}

async function updateProductPlanStatus(productPlanId: any, status: string) {
    const instance = getPublicInstance("common");
    return await instance.put(`product-plan/${productPlanId}/status/${status}`)
}

async function updateProductPlan(productPlanId: any, payload: any) {
    const instance = getPublicInstance("common");
    return await instance.put(API_CONFIG.productPlanList + '/' + productPlanId, payload)
}

async function getEditProductPlanData(productPlanId: any) {
    const instance = getPublicInstance("common");
    return await instance.get(API_CONFIG.productPlanList + '/' + productPlanId);
}


async function getLoanProductList(params:any=null) {
    const instance = getPublicInstance("common");
    return await instance.get('list/loan_product', {params})
}

async function  getLeadById(id:string, params:any=null) {
    const instance = getProtectedInstanceForUploadDocument("distribution");
    return await instance.get(`get/case/${id}?workFlowType=ASSISTED`, {params})
}

async function getNextTaskV2(payload:any) {
    const instance = getPublicInstance("distribution");
    const url = 'product-workflow/get-task';
    return await instance.post(url, payload);
}

async function listDocumentV2(caseId:string) {
    const instance = getProtectedInstanceForUploadDocument();
    const url = `list/document/case/${caseId}`;
    return await instance.get(url);
}

async function  getInvoiceList(params:any) {
    const instance = getPublicInstance("distribution");
    return await instance.get('invoice',{params});
}

async function listDecisioning(caseId:string,checkAvailability:boolean, decisioningMasterId?:string, journeyId?:string) {
    const instance = getPublicInstance("common");
    let url = `decision/data/list?caseId=${caseId}&checkAvailability=${checkAvailability}`;
    if((decisioningMasterId?.length ?? 0) > 0){
        url = url + `&decisioningMasterId=${decisioningMasterId }`;
    }
    if((journeyId?.length ?? 0) > 0){
        url = url + `&journeyIds=${journeyId}`;
    }
    return await instance.get(url);
}

async function getIncompleteDisbursalList(params?: any) {
    const instance = getPublicInstance("distribution")
    return await instance.get('invoice/fetch/disbursal', { params });   
}

async function getInvoicePreview(payload:any) {
    const instance = getPublicInstance("distribution")
    return await instance.post('invoice/preview', payload);  
}

async function getInvoiceCreate(payload:any) {
    const instance = getPublicInstance("distribution")
    return await instance.post('invoice/create', payload);  
}

async function getDownloadExcel(payload:any) {
    const instance = getProtectedInstanceForBlob("distribution")
    // console.log('payload1', payload)
    return await instance.post('generate/invoice-disbursal', payload);  
}




export const API_SERVICE = {
    login,
    getOtp,
    verifyOtp,
    setUpdatePassword,
    getTenantList,
    assignDataList,
    assignToSearch,
    getListTenants,
    updateTenant,
    getLoanProduct,
    getAllLoanProduct,
    handleErrors,
    getAppContent,
    getAppContentFAQ,
    addAppContent,
    updateAppContent,
    deleteAppContent,
    getPropertyList,
    getSubPropertyList,
    editPropertyData,
    addProperty,
    deleteProperty,
    updateProperty,
    editDocumentList,
    fileUpload,
    addDocumentTenant,
    deleteDocument,
    getCollectionList,
    getObjectList,
    createObject,
    updateObject,
    editObjectData,
    getObjectProperty,
    deleteObject,
    createCollection,
    editCollectionData,
    updateCollection,
    deleteCollection,
    getUploadDocument,
    getProductList,
    getEditProductData,
    createProduct,
    updateProduct,
    productFormTemplate,
    startProductWorkflow,
    submitProductWorkflow,
    getNextTaskProductWorkflow,
    getCategoryList,
    getTypes,
    generateReportByReportType,
    cleanLeads,
    getMasterProductList,
    productClone,
    mapMasterLoanProduct,
    mapJourneyConnector,
    addProductJourney,
    getProductWorkflowById,
    formTemplateList,
    updateProductJourney,
    getDecisioningList,
    addDecisioning,
    editDecisioningData,
    updateDecisioning,
    updateDecisioningProfile,
    duplicateDecisioning,
    getActionList,
    listAllTheFinanciers,
    getFiList,
    getPropertiesList,
    getScorecardPublisList,
    getDecisioningPublishList,
    generateDecisioning,
    generateFormTemplate,
    decisionVaildate,
    testDecisionMaster,
    validateConditionExpression,
    updateStatusOfDocumentCategory,
    createDocumentCategory,
    updateDocumentCategorys,
    getDocumentCategorys,
    updateStatusOfDocumentType,
    createDocumentType,
    updateDocumentType,
    getDocumentTypes,
    createDocumentProduct,
    updateDocumentProduct,
    getDocumentProducts,
    addProduct,
    addDocument,
    invoiceAddDocument,
    invoiceAddSignedDoc,
    getProductTypes,
    getProductActionList,
    updateActionStatus,
    productPropertiesList,
    addProductProperty,
    addOfferCalculation,
    getOfferCalculationList,
    publishProductWorkflow,
    listProductWorkflowPublish,
    uploadOfferCalculation,
    updateOfferCalculation,
    offerCalculationCopyJson,
    offerCalculationPasteJson,
    getOfferCalculationById,
    offerCalculationPublishDetails,
    testOfferCalculationMaster,
    publishOfferCalculation,
    publishOfferCalculationById,
    getDefaultMasterWorkflow,
    getDispositionList,
    updateDisposition,
    getDecisioningAuditList,
    getDecentroFinbox,
    getPublishDetails,
    getProductProperty,
    fetchMasterProductList,
    addWorkFlowProduct,
    getWorkflowLitelist,
    getStartFormConfiguration,
    updateStartFormConfiguration,
    getAssistedJourneyDdlData,
    checkCapchaRequired,
    fetchProductPlanList,
    addProductPlan,
    deleteProductPlanById,
    updateProductPlanStatus,
    updateProductPlan,
    getEditProductPlanData,
    getLoanProductList,
    getLeadById,
    getNextTaskV2,
    listDocumentV2,
    getInvoiceList,
    listDecisioning,
    getIncompleteDisbursalList,
    getInvoicePreview,
    getInvoiceCreate,
    getDownloadExcel
}